import { useSelector } from "react-redux";

const useGetUsers = ({ email, userId }) => {
  const users = useSelector((state) => state.users && state.users);

  if (!users) {
    return [];
  }

  const user = users.users.find(
    (user) => (user.email === user && user) || (user.uuid === userId && userId),
  );

  const patients = users.users.filter((user) => user.type === "patient");
  const verifiedPatients = patients.filter((user) => user.verified === "true");
  const unverifiedPatients = patients.filter(
    (user) => user.verified === "false",
  );
  const temporaryPatients = users.users.filter(
    (user) => user.type === "temporaryPatient",
  );
  const allPatients = [...patients, ...temporaryPatients];

  const allTherapists = users.users.filter((user) => user.type === "therapist");
  const verifiedTherapists = allTherapists.filter(
    (user) => user.verified === "true",
  );
  const unverifiedTherapists = allTherapists.filter(
    (user) => user.verified === "false",
  );

  return {
    users: users.users,
    user: user,
    patients,
    temporaryPatients,
    allTherapists,
    verifiedTherapists,
    unverifiedTherapists,
    allPatients,
    verifiedPatients,
    unverifiedPatients,
  };
};

export default useGetUsers;
